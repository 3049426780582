import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  TextField,
  IconButton,
} from "@mui/material";
import React, { useContext } from "react";
import { useEffect } from "react";
import CreditRiskCard from "../../../Components/CrifCard/CreditCard";
import {
  Exceptional,
  Fair,
  Good,
  NewToCredit,
  NotAvailable,
  Poor,
  VeryGood,
} from "../../../Components/StatusChip";
import { GlobalContext } from "../../../Context/GlobalContext";
import { f, responseFormat, scoreCheckText } from "../../../utility/Formater";
import { decryptData, fetchRejectedCoapps, updateFatherName } from "../../../api";
import { useState } from "react";
import CreditEngineComp from "../../../Components/CreditEngineComp/CreditEngineComp";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { UserContext } from "../../../Context/UserContext";
import { toast } from "react-toastify";
import useApplication from "../../../hooks/useApplication";
import { RefreshOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { generateCif } from "../../../api";
import { generateEquifax } from "../../../api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CoCustomerDetails = ({ setOpenCSTC, isLoading }) => {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const { user } = useContext(UserContext);
  const { getApplicationDetails } = useApplication();
  const [rejectedCoapplicants, setRejectedCoapplicants] = useState([]);
  const [creditEngineData, setCreditEngineData] = React.useState({
    open: false,
    type: "customer",
  });

  const [crifData, setCrifData] = useState({});
  const [openCrifData, setOpenCrifData] = useState({
    open: false,
    loading: false,
  });
  const [showCifButton, setShowCifButton] = useState(true);
  const [isLoadingCif, setIsLoadingCif] = useState(false);
  const [equifax, setEquifax] = useState(false);
  const [activeCoTab, setActiveCoTab] = useState("creditEngine");
  const [fatherNameLoading, setFatherNameLoading] = useState(false);
  const [fatherName, setFatherName] = useState(false);
  const [isEditFatherName, setIsEditFatherName] = useState(false);

  const fetchRejectedCoapplicants = async (appId) => {
    const { data } = await fetchRejectedCoapps(appId);
    if (data?.status) {
      setRejectedCoapplicants([...(updateDetails?.deleted_co_customer?.map(itm=>itm?.data) || []),...data?.data]);
    }
  };

  const handleClickOpen = () => {
    setOpenCrifData({
      open: true,
      loading: true,
    });
  };

  const handleClose = () => {
    setOpenCrifData({
      open: false,
      loading: false,
    });
  };

  const handleViewCrif = async () => {
    setOpenCrifData({
      open: true,
      loading: true,
    });
    try {
      let { data } = await decryptData({
        data: updateDetails?.co_crif_data,
      });
      if (data?.status) {
        setCrifData(data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setOpenCrifData({ open: true, loading: false });
  };

  const handleGenerateCif = async () => {
    try {
      const payload = {
        application_id: updateDetails?.application_id,
        customer_type: "co-customer",
      };
      const response = await generateCif(payload);
      setIsLoadingCif(false)
      if (response?.data?.cif_id) {
        setUpdateDetails(prevDetails => ({
          ...prevDetails,
          co_app_cif_id: response.data.cif_id,
        }));
        toast.success("CIF ID generated successfully!");
        setShowCifButton(false);
      } else if (response?.data?.failedDocs?.length > 0) {
        const failedDocsList = response?.data?.failedDocs
          .map(doc => `${doc?.doc} (${doc?.reason})`)
          .join(", ");
        toast.warning(
          `CIF ID generated, but some documents failed: ${failedDocsList}`
        );
      } else {
        toast.error(response?.data?.message || "Failed to generate CIF ID.");
      }
    } catch (error) {
      setShowCifButton(true);
      setIsLoadingCif(false)
      if (error?.message === "Bank not verified") {
        toast.error("Bank details are not verified. Please verify the bank first.");
      } else if (error?.message?.includes("Missing")) {
        toast.error(error.message);
      } else if (error?.message === "Application not found") {
        toast.error("Application not found. Please check the Application ID.");
      } else {
        toast.error(error?.response?.data?.message || "Something went wrong.");
      }
    }
  };

  const handleGenerateEquifax = async () => {
    try {
      if (!updateDetails?.application_id) {
        console.error("Application ID not found");
        return;
      }
      const payload = { application_id: updateDetails.application_id };
      const response = await generateEquifax(payload);
      setEquifax(true);
    } catch (error) {
      console.error("Error generating Equifax score:", error);
    }
  };

    const handleUpdateFatherName = async () => {
      try {
        if (
          updateDetails?.co_app_father_name === "" ||
          updateDetails?.co_app_father_name === null ||
          updateDetails?.co_app_father_name === undefined
        ) {
          await updateFatherName({
            father_name: fatherName,
            customer_id: updateDetails?.customer_id,
            type: "co-customer"
          });
          setUpdateDetails((prev) => ({
            ...prev,
            co_app_father_name: fatherName,
          }));
    
          setIsEditFatherName(false);
          toast.success("Father's name updated succesfully!!!")
        }
      } catch (err) {
        console.error("Error editing father's name", err);
        toast.error("Error updating Father's name", err);
      } finally {
        setFatherNameLoading(false);
      }
    };  

  useEffect(() => {
    fetchRejectedCoapplicants(updateDetails?.application_id);
  }, [updateDetails]);

  useEffect(() => {

  },[equifax]);

  useEffect(() => {

  },[fatherName])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="overview-card">
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <p className="sub-heading">Basic Details</p>
                  <ListItemComp
                    heading={"Name:"}
                    text={f(updateDetails?.co_app_customer_name)}
                  />
                  <ListItemComp
                    heading={"Mobile:"}
                    text={f(updateDetails?.co_app_mobile_number)}
                  />
                  <ListItemComp
                    heading={"Email:"}
                    text={f(updateDetails?.co_app_email)}
                  />
                  <ListItemComp
                    heading={"Gender:"}
                    text={f(updateDetails?.co_app_gender)}
                  />
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      heading={"Income:"}
                      text={f(updateDetails?.co_app_income)}
                    />
                  )}
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      heading={"Education:"}
                      text={f(updateDetails?.co_app_education)}
                    />
                  )}
                  <ListItemComp
                    heading={"Relationship with borrower:"}
                    text={f(updateDetails?.co_app_relation_borrower)}
                  />
                 <ListItemComp
                    loading={isLoading}
                    heading={"Father's Name:"}
                    text={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {fatherNameLoading ? (
                            <CircularProgress size={20} />
                          ) : isEditFatherName ? (
                          <>
                            <TextField
                              variant="outlined"
                              size="small"
                              onChange={(e) => setFatherName(e.target.value)}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                setFatherNameLoading(true);
                                setIsEditFatherName(false);
                                setFatherName(fatherName);
                                handleUpdateFatherName();
                              }}
                            >
                              Save
                            </Button>
                          </>
                        ) : (
                          <>
                            {f(updateDetails?.co_app_father_name) || fatherName}
                            {(updateDetails?.co_app_father_name === null ||
                              updateDetails?.co_app_father_name === undefined ||
                              updateDetails?.co_app_father_name === "") && (
                              <IconButton
                                size="small"
                                onClick={() => setIsEditFatherName(true)}
                                sx={{ color: 'primary.main' }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            )}
                          </>
                        )}
                      </div>
                    }
                  />
                  {
                    (updateDetails?.nbfc_id == "MF00006") &&                       
                    (<ListItemComp
                      heading={"Cif Id:"}
                      text={isLoadingCif ? (<CircularProgress size={20}/>) : (f(updateDetails?.co_app_cif_id))}
                    />)
                  }
                  {(updateDetails?.co_app_cif_id == null || updateDetails?.co_app_cif_id == "" 
                    || updateDetails?.co_app_cif_id == undefined) && (showCifButton) && 
                     (updateDetails?.nbfc_id == "MF00006") && (
                    <div style={{ marginLeft: "60px", marginTop: "8px" }}>
                      <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        size="small"
                        style={{
                          fontSize: 8,
                          padding: 5,
                          margin: "0px 20px",
                        }}
                        onClick={() => {
                          setShowCifButton(false);
                          setIsLoadingCif(true);
                          handleGenerateCif();
                        }}
                        disabled={!showCifButton}
                      >
                        <RefreshOutlined style={{ fontSize: "14px" }} /> Generate Cif Id
                      </Button>
                    </div>
                  )}                                       
                      
                </Grid>
                <Grid item xs={4}>
                  <p className="sub-heading">Pan Details</p>
                  <ListItemComp
                    heading={"Name:"}
                    text={f(updateDetails?.co_app_customer_name)}
                  />
                  <ListItemComp
                    heading={"Number:"}
                    text={f(updateDetails?.co_app_pan_number)}
                  />

                  <div className="pe-4">
                    <hr />
                  </div>

                  <p className="sub-heading">Aadhaar Details</p>
                  <ListItemComp
                    heading={"Name:"}
                    text={f(updateDetails?.co_app_aadhaar_name)}
                  />
                  {/* <ListItemComp
                    heading={"UID:"}
                    text={f(updateDetails?.co_app_aadhaar_number)}
                  /> */}
                  {[1, 5].includes(Number(user?.role)) && <ListItemComp
                    heading={"UID:"}
                    text={
                      [1, 5].includes(Number(user?.role)) &&
                        updateDetails?.co_app_aadhaar_number_unmasked
                        ? f(updateDetails?.co_app_aadhaar_number_unmasked)
                        : f(updateDetails?.co_app_aadhaar_number)
                    }
                  />}
                  <ListItemComp
                    heading={"Gender:"}
                    text={f(updateDetails?.co_app_aadhaar_gender)}
                  />
                  <ListItemComp
                    heading={"Address:"}
                    text={f(updateDetails?.co_app_aadhaar_address)}
                  />
                  <ListItemComp
                    heading={"Aadhaar Linked Mobile No.:"}
                    text={f(updateDetails?.co_app_aadhaar_mobile_number_masked)}
                  />
                  <ListItemComp
                    heading={"Aadhaar Linked Mobile Match:"}
                    text={f(
                      updateDetails?.co_app_aadhaar_mobile_number_matched
                    )}
                  />
                </Grid>
                <Grid item xs={5}>
                  <p className="sub-heading">Residential Address</p>
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      heading={"Is Property Owned:"}
                      text={f(
                        updateDetails?.co_app_current_residential_owned,
                        "bool"
                      )}
                    />
                  )}
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      heading={"Owned By:"}
                      text={f(updateDetails?.co_app_owned_by)}
                    />
                  )}
                  <ListItemComp
                    heading={"City:"}
                    text={f(updateDetails?.co_app_city)}
                  />
                  <ListItemComp
                    heading={"District:"}
                    text={f(updateDetails?.co_app_district)}
                  />
                  <ListItemComp
                    heading={"State:"}
                    text={f(updateDetails?.co_app_state)}
                  />
                  <ListItemComp
                    heading={"Pincode:"}
                    text={f(updateDetails?.co_app_pincode)}
                  />
                  <ListItemComp
                    heading={"Address:"}
                    text={f(updateDetails?.co_app_current_residential_address)}
                  />
                  <ListItemComp
                    heading={"Ownership Proof:"}
                    text={f(updateDetails?.co_customer_ownership_proof)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="overview-card">
            <p className="card-heading" style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  padding: "5px 10px",
                  border: activeCoTab === "creditEngine" ? "2px solid gray" : "none",
                  borderRadius: "4px",
                  backgroundColor: activeCoTab === "creditEngine" ? "#f5f5f5" : "transparent",
                }}
                onClick={() => setActiveCoTab("creditEngine")}
              >
                Credit Engine Data
              </span>

              <span
                style={{
                  cursor: "pointer",
                  padding: "5px 10px",
                  border: activeCoTab === "equifax" ? "2px solid gray" : "none",
                  borderRadius: "4px",
                  backgroundColor: activeCoTab === "equifax" ? "#f5f5f5" : "transparent",
                }}
                onClick={() => setActiveCoTab("equifax")}
              >
                Equifax Score
              </span>

              {[1, 5].includes(Number(user?.role)) &&
                updateDetails?.app_creation_stage !== "CUSTOMER_BASIC_DETAILS" && (
                  <span
                    style={{
                      cursor: "pointer",
                      padding: "2px 10px",
                      background: "#1d1b31",
                      color: "white",
                      borderRadius: "20px",
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      if (activeCoTab === "equifax") {
                        handleGenerateEquifax();
                      } else {
                        setCreditEngineData({ open: true, type: "co_customer" });
                      }
                    }}
                  >
                    <AutorenewIcon sx={{ fontSize: 16 }} />
                    {activeCoTab === "equifax" ? "Generate Equifax" : "Run Engine"}
                  </span>
                )}
            </p>
            <div className="card-content">
              {activeCoTab === "creditEngine" && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ListItemComp
                      heading={"Score Remark:"}
                      text={scoreCheckText(updateDetails?.co_app_crif_score)}
                    />
                    {Number(user?.role) !== 3 && (
                      <ListItemComp
                        heading={"Response:"}
                        text={responseFormat(
                          updateDetails?.co_credit_response,
                          updateDetails?.co_credit_eligible
                        )}
                      />
                    )}
                    {Number(user?.role) !== 3 && (
                      <ListItemComp
                        heading={"Customer Distance:"}
                        text={
                          updateDetails?.credit_engine?.details?.current_distance?.toFixed(2) ?? 0
                        }
                      />
                    )}
                    {Number(user?.role) !== 3 && (
                      <ListItemComp
                        heading={"Co-Customer Distance:"}
                        text={
                          updateDetails?.coApplicantExist ? (
                            updateDetails?.co_app_dealer_distance?.toFixed(2) ?? 0
                          ) : (
                            <NotAvailable />
                          )
                        }
                      />
                    )}
                  </Grid>

                <Grid item xs={6} className="pb-2">
                  <CreditRiskCard
                    application={{
                      credit_pull: {
                        credit_data: {
                          crif_score: updateDetails?.co_app_crif_score,
                        },
                      },
                    }}
                  />
                </Grid>

                {[1, 5].includes(Number(user?.role)) &&
                  updateDetails?.co_crif_data !== null && (
                    <Grid item xs={12}>
                      <hr />

                        <Button
                          onClick={() => {
                            handleViewCrif();
                          }}
                        >
                          <RemoveRedEyeIcon sx={{ mr: 1 }} /> View Report
                        </Button>
                      </Grid>
                    )}
                </Grid>
              )}
              {activeCoTab === "equifax" && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ListItemComp
                      heading={"Score Remark:"}
                      text={
                        updateDetails?.co_app_equifax_score !== undefined &&
                        updateDetails?.co_app_equifax_score !== null
                          ? updateDetails.co_app_equifax_score === -1
                            ? "New To Credit"
                            : updateDetails.co_app_equifax_score >= 650
                            ? "Good"
                            : "Poor"
                          : "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="pb-2">
                    <CreditRiskCard
                      application={{
                        equifax_details: { equifax_score: updateDetails?.co_app_equifax_score },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </Grid>

        {rejectedCoapplicants.length !== 0 && (
          <Grid item xs={rejectedCoapplicants.length ===1?6:12}>
            <div
              style={{
                height: "300px",
                overflowX: "hidden",
                overflowY: "auto",
              }}
              className="overview-card"
            >
              <p className="card-heading">Rejected Co-Applicants</p>
              <div className="card-content w-100">
                <Grid container direction={"row"} spacing={2}>
                  {rejectedCoapplicants?.map((rejected, index) => {
                    return (
                      <>
                        <Grid item xs={rejectedCoapplicants.length ===1?12:4}>
                          <ListItemComp
                            heading="Name"
                            text={rejected?.pan_details?.name}
                          />
                          <ListItemComp
                            heading="Score"
                            text={
                              rejected?.credit_pull?.credit_data?.crif_score
                            }
                          />
                          <ListItemComp
                            heading="Reason"
                            text={rejected?.credit_engine?.message}
                          />
                          <ListItemComp
                            heading="Pan Number"
                            text={rejected?.pan_details?.number}
                          />
                          <ListItemComp
                            heading="Date of Birth"
                            text={rejected?.dob}
                          />{" "}
                          <ListItemComp
                            heading="Mobile Number"
                            text={rejected?.mobile_number}
                          />{" "}
                          <ListItemComp
                            heading="Gender"
                            text={rejected?.gender}
                          />
                          <ListItemComp
                            heading="Pincode"
                            text={
                              rejected?.aadhaar_details?.address_information
                                ?.pincode
                            }
                          />
                          {/* {Object.keys(rejected).map((key) => {
                          return (
                            <>
                              <ListItemComp
                                heading={key}
                                text={rejected[key]}
                              />
                             
                            </>
                          );
                        })} */}
                          {/* <ListItemComp
                    heading={"Eligibility:"}
                    text={f(updateDetails?.co_credit_eligible, "bool")}
                  /> */}
                        </Grid>
                      </>
                    );
                  })}

                  {/* <Grid item xs={6} className="pb-2">
                  <CreditRiskCard
                    application={{
                      credit_pull: {
                        credit_data: { crif_score: updateDetails?.crif_score },
                      },
                    }}
                  />
                </Grid> */}
                </Grid>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      <CreditEngineComp
        open={creditEngineData?.open}
        type={creditEngineData?.type}
        handleClose={() => {
          setCreditEngineData({ open: false, type: "" });
        }}
        handleReload={() => {
          getApplicationDetails(updateDetails?.application_id);
        }}
      />

      <Dialog
        open={openCrifData?.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>Crif Report</DialogTitle>
        <DialogContent>
          {openCrifData?.loading ? (
            <p>
              {" "}
              <CircularProgress size={12} /> Loading
            </p>
          ) : (
            <pre>{JSON.stringify(crifData, null, 2)}</pre>
          )}
          {/* <ReactJson
            src={crifData}
            collapsed={false}
            displayDataTypes={false}
            displayArrayKey={false}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoCustomerDetails;

const ListItemComp = ({ icon, heading, text }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key">
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "13px" }}
        className="list-value"
        primary={text}
      />
    </ListItem>
  );
};
