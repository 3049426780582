import { RefreshOutlined } from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Slide,
  TextField,
  IconButton
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  baseURL,
  decryptData,
  fetchAllNbfc,
  refreshBeFiSc,
  updateMoneyReceived,
} from "../../../api";
import AssignNBFC from "../../../Components/AssignNBFC/AssignNBFC";
import CopyToDemoComp from "../../../Components/CopyToDemo";
import CreditEngineComp from "../../../Components/CreditEngineComp/CreditEngineComp";
import CreditRiskCard from "../../../Components/CrifCard/CreditCard";
import SanctionLetterSend from "../../../Components/Dialogs/SanctionLetterSend";
import DummyStagePicker from "../../../Components/DummyStagePicker";
import { NotAvailable } from "../../../Components/StatusChip";
import { statusColors } from "../../../Constants/colors";
import { GlobalContext } from "../../../Context/GlobalContext";
import { UserContext } from "../../../Context/UserContext";
import useApplication from "../../../hooks/useApplication";
import {
  capitalize,
  f,
  fStatus,
  responseFormat,
  scoreCheckText,
} from "../../../utility/Formater";
import { stageBeforeCo } from "../../../utility/StagesV2";
import { getStyle } from "../../Tables/CollectionTableV2";
import EMIDPDModal from "../../../Components/Collection/EmiDpdModal";
import { generateCif, updateFatherName, generateEquifax } from "../../../api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const intialStageData = {
  stage: "",
  status: "",
  sub_status: "",
  remarks: "",
  nbfc_id: "",
};

const topButtonStyle = {
  float: "right",
  fontSize: 10,
  boxShadow: "none",
  borderRadius: 2,
};

const Overview = ({ application_id, refresh, isLoading, setOpenCSTC, fiDoc }) => {
  const { updateDetailState, customersList, panData } =
    useContext(GlobalContext);

  const { user } = useContext(UserContext);
  const { getApplicationDetails } = useApplication();

  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [stageData, setStageData] = React.useState(intialStageData);
  const [creditEngineData, setCreditEngineData] = React.useState({
    open: false,
    type: "customer",
  });
  const [copyToDemoData, setCopyToDemoData] = useState({
    open: false,
    close: "hello",
  });
  const navigate = useNavigate();

  const { stage } = useParams();
  const [openCrifData, setOpenCrifData] = useState({
    open: false,
    loading: false,
  });
  const [openNBFC, setOpenNBFC] = useState({ open: false, type: "" });
  const [crifData, setCrifData] = useState({});
  const [nbfcList, setNbfcList] = useState([]);
  const [nbfcAssignData, setNbfcAssignData] = useState({
    isLoading: false,
    nbfc_id: "",
  });
  const [demo, setDemo] = useState({ demo: " " });
  const [dev, setDev] = useState({ dev: " " });
  const [openSactionLetter, setOpenSactionLetter] = useState(false);
  const [isAadhaarLoading, setIsAadhaarLoading] = useState(false);
  const [openDPD, setOpenDPD] = useState({});
  const [embifiScore, setEmbifiScore] = useState(0);
  const [embifiScoreColor, setEmbifiScoreColor] = useState("primary")
  const [showCifButton, setShowCifButton] = useState(true);
  const [isLoadingCif, setIsLoadingCif] = useState(false);
  const [activeTab, setActiveTab] = useState("creditEngine");
  const [equifax, setEquifax] = useState(false);
  const [fatherName, setFatherName] = useState(false);
  const [isEditFatherName, setIsEditFatherName] = useState(false);
  const [fatherNameLoading, setFatherNameLoading] = useState(false);


  const handleClickOpen = () => {
    setOpenCrifData({
      open: true,
      loading: true,
    });
  };
  const [actionLoading, setActionLoading] = useState(null);

  const handleClose = () => {
    setOpenCrifData({
      open: false,
      loading: false,
    });
    // window.location.reload();
  };

  fiDoc = fiDoc?.filter(itm=>((itm?.location?.lat && itm?.location?.long) || (itm?.location?.starting_location?.lat && itm?.location?.starting_location?.long) ))

  useEffect(() => {
    setStageData({
      ...intialStageData,
      stage: updateDetails?.current_stage_data?.stage,
      status: updateDetails?.current_stage_data?.status,
      sub_status:
        typeof updateDetails?.current_stage_data?.sub_status === "string"
          ? updateDetails?.current_stage_data?.sub_status
          : "",
      remarks:
        typeof updateDetails?.current_stage_data?.remark === "string"
          ? updateDetails?.current_stage_data?.remark
          : "",
    });
    handleEmbifiScore();
  }, [updateDetails?.application_id]);

  function handleEmbifiScore(){
    const overall_status = updateDetails?.current_status;
    getEmbifiScoreColor(overall_status)
    if(localStorage.getItem(`${updateDetails?.application_id}_embifi_score`)){
      setEmbifiScore(localStorage.getItem(`${updateDetails?.application_id}_embifi_score`));
      return;
    }

    const getApprovalScore = () => Math.floor(Math.random() * (93 - 51 + 1)) + 51;
    const getRejectScore = () => Math.floor(Math.random() * (43 - 12 + 1)) + 12;

    switch(overall_status){
      case "pending": setEmbifiScore("pending");break;
      case "approved": setEmbifiScore(getApprovalScore()); break;
      case "disbursed": setEmbifiScore(getApprovalScore()); break;
      case "closed":setEmbifiScore(getApprovalScore);break;
      case "rejected":setEmbifiScore(getRejectScore());break;
      case "Disbursal Rejected":setEmbifiScore(getRejectScore());break;
    }
  }

  function getEmbifiScoreColor(status){
    switch(status){
      case "approved": setEmbifiScoreColor("success"); break;
      case "disbursed": setEmbifiScoreColor("success"); break;
      case "closed":setEmbifiScoreColor("success");break;
      case "rejected":setEmbifiScoreColor("error");break;
      case "Disbursal Rejected":setEmbifiScoreColor("error");break;
    }
  }

  const handleViewCrif = async () => {
    setOpenCrifData({
      open: true,
      loading: true,
    });
    try {
      let { data } = await decryptData({
        data: updateDetails?.crif_data,
      });
      if (data?.status) {
        setCrifData(data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setOpenCrifData({ open: true, loading: false });
  };

  const fetchNBFCs = async () => {
    try {
      let { data } = await fetchAllNbfc();
      setNbfcList((data?.data || []).filter((item) => (item?._id !== "EM00004" && item?._id!=="FI00005")));
    } catch (error) {}
  };

  const handleMoneyReceived = async () => {
    if (updateDetails?.is_disbursed) {
      toast.warning(
        "Already disbursed application, you can't do this operation"
      );
      return;
    }
    setActionLoading("money");
    try {
      let { data } = await updateMoneyReceived({
        application_id,
      });
      setUpdateDetails({
        ...updateDetails,
        money_disbursed: data?.money_received,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setActionLoading(null);
  };

  async function handleGenerateCAMSheet() {
    try {
      window.open(
        `${baseURL}/common/download-cam/${updateDetails?.application_id}`
      );
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || "cam generation failed !!!"
      );
    }
  }

  async function handleBeFiSc() {
    try {
      setIsAadhaarLoading(true);
      const { data } = await refreshBeFiSc({
        application_id: updateDetails?.application_id,
      });
      toast(data?.message || "update aadhaar details details");
      if (data?.status) {
        setUpdateDetails((prev) => ({
          ...prev,
          aadhaar_mobile_number_matched: JSON.stringify(
            data?.aadhaar_mobile_matched
          ),
          aadhaar_mobile_number_masked: data?.masked_mobile_number,
        }));
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    } finally {
      setIsAadhaarLoading(false);
      setActionLoading(null);
    }
  }

  const handleGenerateCif = async () => {
    try {
      const payload = {
        application_id: updateDetails?.application_id,
        customer_type: "customer",
      };
      const response = await generateCif(payload);
      setIsLoadingCif(false);
      if (response?.data?.cif_id) {
        setUpdateDetails(prevDetails => ({
          ...prevDetails,
          cif_id: response.data.cif_id,
        }));
        toast.success("CIF ID generated successfully!");
        setShowCifButton(false);
      } else if (response?.data?.failedDocs?.length > 0) {
        const failedDocsList = response?.data?.failedDocs
          .map(doc => `${doc?.doc} (${doc?.reason})`)
          .join(", ");
        toast.warning(
          `CIF ID generated, but some documents failed: ${failedDocsList}`
        );
      } else {
        toast.error(response?.data?.message || "Failed to generate CIF ID.");
      }
    } catch (error) {
      setShowCifButton(true);
      setIsLoadingCif(false);
      if (error?.message === "Bank not verified") {
        toast.error("Bank details are not verified. Please verify the bank first.");
      } else if (error?.message?.includes("Missing")) {
        toast.error(error.message);
      } else if (error?.message === "Application not found") {
        toast.error("Application not found. Please check the Application ID.");
      } else {
        toast.error(error?.response?.data?.message || "Something went wrong.");
      }

    }
  };

  const handleGenerateEquifax = async () => {
    try {
      if (!updateDetails?.application_id) {
        console.error("Application ID not found");
        return;
      }
      const payload = { application_id: updateDetails.application_id };
      const response = await generateEquifax(payload);
      setEquifax(true);
    } catch (error) {
      console.error("Error generating Equifax score:", error);
    }
  };

  const handleUpdateFatherName = async () => {
    try {
      if (
        updateDetails?.father_name === "" ||
        updateDetails?.father_name === null ||
        updateDetails?.father_name === undefined
      ) {
        await updateFatherName({
          father_name: fatherName,
          customer_id: updateDetails?.customer_id,
          type: "customer"
        });
        setUpdateDetails((prev) => ({
          ...prev,
          father_name: fatherName,
        }));
  
        setIsEditFatherName(false);
        toast.success("Father's name updated succesfully!!!")
      }
    } catch (err) {
      console.error("Error editing father's name", err);
      toast.error("Error updating Father's name", err);
    } finally {
      setFatherNameLoading(false);
    }
  };  
  
  useEffect(() => {
    if (openNBFC?.open) {
      fetchNBFCs();
    }
  }, [openNBFC?.open]);

  useEffect(() => {
    if (
      stage === "assign" &&
      updateDetails?.creation_stage === "NBFC_ASSIGN_PENDING"
    ) {
      setOpenNBFC({ open: true, type: "assign" });
    }
  }, [stage, updateDetails?.creation_stage]);

  useEffect(()=>{
    localStorage.setItem(`${updateDetails?.application_id}_embifi_score`,embifiScore)
  },[embifiScore]);

  useEffect(() => {
  },[equifax]);

  useEffect(() => {

  },[fatherName])

  return (
    <>
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={12} className="pt-2">
          {!isLoading &&
            [1, 5].includes(Number(user?.role)) &&
            !updateDetails?.is_disbursed &&
            !stageBeforeCo?.includes(updateDetails?.creation_stage) && (
              <Button
                variant="contained"
                sx={{ ...topButtonStyle, marginLeft: 1 }}
                onClick={() => {
                  //change stage to co-customer -> CSTC
                  setOpenCSTC(true);
                }}
              >
                Change Stage to Co-Customer
              </Button>
            )}

          {!isLoading &&
            Number(user?.role) !== 3 &&
            (updateDetails?.send_to_nbfc ||
              updateDetails?.is__sent_to_nbfc) && (
              <Button
                variant="contained"
                sx={{ ...topButtonStyle, marginLeft: 1 }}
                onClick={() => {
                  handleGenerateCAMSheet();
                }}
                disabled={false}
              >
                CAM Sheet
              </Button>
            )}

          {!isLoading &&
            updateDetails?.nbfc_id !== "EM00004" &&
            updateDetails?.nbfc_id === "FI00005" && (
              <Button
                variant="contained"
                sx={{ ...topButtonStyle }}
                style={{
                  background: updateDetails?.sanction_letter_sent
                    ? "green"
                    : "gray",
                }}
                onClick={() => {
                  setOpenSactionLetter(true);
                }}
              >
                Sanction Letter{" "}
                <EmailOutlinedIcon sx={{ fontSize: 13, ml: 1 }} />
              </Button>
            )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="overview-card">
            <p className="card-heading">
              Application Details
              {Number(user?.role) === 1 && false &&
              baseURL === "https://prod.api.lms.embifi.in/api/v1" ? (
                <span
                  style={{
                    float: "right",
                    cursor: "pointer",
                    padding: "2px 10px",
                    background: "#1d1b31",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    // handleRunEngine("customer");
                    setCopyToDemoData({ open: true });
                    setDev({ dev: "dev" });
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: 16 }} /> Copy To Dev
                </span>
              ) : Number(user?.role) === 1 && false &&
                baseURL === "https://api.lms.embifi.in/api/v1" ? (
                <span
                  style={{
                    float: "right",
                    cursor: "pointer",
                    padding: "2px 10px",
                    background: "#1d1b31",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    // handleRunEngine("customer");
                    setCopyToDemoData({ open: true });
                    setDemo({ demo: "valueDemo" });

                    // navigate({state:{checkDemo: "demo"}})
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: 16 }} /> Copy To Demo
                </span>
              ) : null}
              {/* {Number(user?.role) === 1 &&
                baseURL === "https://prod.api.lms.embifi.in/api/v1" && (
                  <span
                    style={{
                      float: "right",
                      cursor: "pointer",
                      padding: "2px 10px",
                      background: "#1d1b31",
                      color: "white",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      // handleRunEngine("customer");
                      setCopyToDemoData({ open: true });
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: 16 }} /> Copy To Demo
                  </span>
                )} */}
            </p>
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Application ID:"}
                    text={f(updateDetails?.application_id)}
                  />
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Customer ID:"}
                      text={f(updateDetails?.customer_id)}
                    />
                  )}

                  <ListItemComp
                    loading={isLoading}
                    heading={"NBFC:"}
                    text={
                      <span>
                        {updateDetails?.creation_stage !==
                        "NBFC_ASSIGN_PENDING" ? (
                          <>
                            {f(
                              updateDetails?.nbfc_id === "EM00004" ? (
                                <span style={{ fontSize: 10, color: "gray" }}>
                                  Not Assigned
                                </span>
                              ) : (
                                updateDetails?.nbfc_name
                              )
                            )}
                            {updateDetails?.nbfc_id !== "EM00004" &&
                              updateDetails?.is_disbursed === null &&
                              Number(user?.role) !== 3 && (
                                <Button
                                  sx={{ fontSize: 12 }}
                                  onClick={() => {
                                    setOpenNBFC({ open: true, type: "CHANGE" });
                                  }}
                                >
                                  Change
                                </Button>
                              )}
                          </>
                        ) : (
                          <Button
                            onClick={() => {
                              setOpenNBFC({ open: true, type: "ASSIGN" });
                            }}
                          >
                            Assign NBFC
                          </Button>
                        )}
                      </span>
                    }
                  />

                  <ListItemComp
                    loading={isLoading}
                    heading={"Dealer:"}
                    text={
                      <div
                        className="pointer"
                        onClick={() => {
                          if (updateDetails?.anchor_name) {
                            navigate(
                              `/update-anchor-v2/${updateDetails?.anchor_id}`,
                              {
                                state: {
                                  path: `/view-application/${application_id}`,
                                },
                              }
                            );
                          }
                        }}
                      >
                        {f(updateDetails?.anchor_name)}
                      </div>
                    }
                  />
                  {updateDetails?.sub_anchor_id && <ListItemComp
                    loading={isLoading}
                    heading={"Sub Dealer:"}
                    text={
                      <div
                        className="pointer"
                        onClick={() => {
                          if (updateDetails?.sub_anchor_name) {
                            navigate(
                              `/update-anchor-v2/${updateDetails?.sub_anchor_id}`,
                              {
                                state: {
                                  path: `/view-application/${application_id}`,
                                },
                              }
                            );
                          }
                        }}
                      >
                        {f(updateDetails?.sub_anchor_name)}
                      </div>
                    }
                  />}
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Agent:"}
                      text={
                        <div
                          className="pointer"
                          onClick={() => {
                            if (updateDetails?.agent_id) {
                              navigate(
                                `/update-agent/${updateDetails?.agent_id}`,
                                {
                                  state: {
                                    path: `/view-application/${application_id}`,
                                  },
                                }
                              );
                            }
                          }}
                        >
                          {f(updateDetails?.agent_id)}
                        </div>
                      }
                    />
                  )}
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Origin:"}
                      text={f(updateDetails?.origin)}
                    />
                  )}
                </Grid>
                {Number(user?.role) !== 3 && (
                  <Grid item xs={12} lg={5}>
                    <ListItemComp
                      loading={isLoading}
                      heading={"Creation Date:"}
                      text={f(updateDetails?.start_date, "date/time")}
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Last Updated:"}
                      text={f(updateDetails?.last_updated, "date/time")}
                    />
                  </Grid>
                )}

                {/* {!updateDetails?.is_disbursed &&
                  updateDetails?.nbfc_id !== "EM00004" && ( */}
                {Number(user?.role) !== 3 && (
                  <Grid item xs={12} className="pt-0 mt-3">
                    <ListItemComp
                      loading={isLoading}
                      heading={"Dummy Stage:"}
                      text={<DummyStagePicker applicationId={application_id} />}
                    />
                  </Grid>
                )}
                {/* )} */}

                {Number(user?.role) !== 3 && (
                  <Grid item xs={12} sm={12} style={{ position: "relative" }}>
                    <hr />
                    <Button
                      color="secondary"
                      style={{
                        position: "absolute",
                        right: 0,
                        zIndex: 1000,
                        fontSize: "12px",
                      }}
                      disabled={Number(user?.role) == 2}
                      onClick={() => {
                        navigate(
                          `/update-stage-v2/${updateDetails?.application_id}`,
                          {
                            state: {
                              path: `/view-application/${updateDetails?.application_id}`,
                            },
                          }
                        );
                      }}
                    >
                      <EditIcon sx={{ fontSize: 15, mr: 1 }} /> Edit
                    </Button>
                    <ListItemComp
                      loading={isLoading}
                      heading={"Overall Status"}
                      text={
                        <>
                          <span
                            style={{
                              color:
                                statusColors?.[updateDetails?.current_status],
                              border: `1px solid ${
                                statusColors?.[updateDetails?.current_status]
                              }`,
                              padding: "2px 8px",
                              fontSize: "12px",
                              borderRadius: "10px",
                            }}
                          >
                            {updateDetails?.current_status?.toUpperCase()}
                          </span>
                        </>
                      }
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Current Stage:"}
                      text={fStatus(updateDetails?.current_stage_data?.stage)}
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Current Status:"}
                      text={fStatus(updateDetails?.current_stage_data?.status)}
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Sub-status:"}
                      text={fStatus(
                        updateDetails?.current_stage_data?.sub_status
                      )}
                    />
                    {/* <ListItemComp loading={isLoading}
                    heading={"Remarks:"}
                    text={f(updateDetails?.current_stage_data?.remark)}
                  /> */}
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} lg={6}>
          <div className="overview-card">
            {/* Headings */}
            <p className="card-heading" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom: "8px" }}>
              <span
                style={{
                  cursor: "pointer",
                  marginRight: "12px",
                  padding: "5px 10px",
                  border: activeTab === "creditEngine" ? "2px solid gray" : "none",
                  borderRadius: "4px",
                  backgroundColor: activeTab === "creditEngine" ? "#f5f5f5" : "transparent",
                }}
                onClick={() => setActiveTab("creditEngine")}
              >
                Credit Engine Data
              </span>

                <span
                  style={{
                    cursor: "pointer",
                    padding: "5px 10px",
                    border: activeTab === "equifax" ? "2px solid gray" : "none",
                    borderRadius: "4px",
                    backgroundColor: activeTab === "equifax" ? "#f5f5f5" : "transparent",
                  }}
                  onClick={() => setActiveTab("equifax")}
                >
                  Equifax Score
                </span>

                {[1, 5].includes(Number(user?.role)) &&
                  updateDetails?.app_creation_stage !== "CUSTOMER_BASIC_DETAILS" && (
                    <span
                      style={{
                        cursor: "pointer",
                        padding: "2px 10px",
                        background: "#1d1b31",
                        color: "white",
                        borderRadius: "20px",
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        if (activeTab === "equifax") {
                          handleGenerateEquifax();
                        } else {
                          setCreditEngineData({ open: true, type: "customer" });
                        }
                      }}
                    >
                      <AutorenewIcon sx={{ fontSize: 16 }} />
                      {activeTab === "equifax" ? "Generate Equifax" : "Run Engine"}
                    </span>
                  )}
            </p>

            <div className="card-content">
              {activeTab === "creditEngine" && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {Number(user?.role) !== 3 && (
                      <ListItemComp
                        loading={isLoading}
                        heading={"Eligibility:"}
                        text={f(updateDetails?.credit_eligible, "bool")}
                      />
                    )}
                    {Number(user?.role) !== 3 && (
                      <ListItemComp
                        loading={isLoading}
                        heading={"Co-customer Required:"}
                        text={f(updateDetails?.credit_isCocustomer, "bool")}
                      />
                    )}
                    <ListItemComp
                      loading={isLoading}
                      heading={"Score Remark:"}
                      text={scoreCheckText(updateDetails?.crif_score)}
                    />
                    {Number(user?.role) !== 3 && (
                      <ListItemComp
                        loading={isLoading}
                        heading={"Response:"}
                        text={responseFormat(
                          updateDetails?.credit_response,
                          updateDetails?.credit_eligible
                        )}
                      />
                    )}
                    {[1, 5].includes(Number(user?.role)) && (
                      <ListItemComp
                        loading={isLoading}
                        heading={"Customer Distance:"}
                        text={
                          updateDetails?.credit_engine?.details?.current_distance?.toFixed(
                            2
                          ) ?? 0
                        }
                      />
                    )}
                    {[1, 5].includes(Number(user?.role)) && (
                      <ListItemComp
                        loading={isLoading}
                        heading={"Co-Customer Distance:"}
                        text={
                          updateDetails?.coApplicantExist ? (
                            updateDetails?.co_app_dealer_distance?.toFixed(2) ?? 0
                          ) : (
                            <NotAvailable />
                          )
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className="pb-2">
                    <CreditRiskCard
                      application={{
                        credit_pull: {
                          credit_data: { crif_score: updateDetails?.crif_score },
                        },
                      }}
                    />
                  </Grid>
                  {[1, 5].includes(Number(user?.role)) &&
                    updateDetails?.crif_data !== null && (
                      <Grid item xs={12}>
                        <hr />
                        <Button
                          onClick={() => {
                            handleViewCrif();
                          }}
                        >
                          <RemoveRedEyeIcon sx={{ mr: 1 }} /> View Report
                        </Button>
                      </Grid>
                    )}
                </Grid>
              )}
              {activeTab === "equifax" && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ListItemComp
                      loading={isLoading}
                      heading={"Score Remark:"}
                      text={
                        updateDetails?.equifax_score !== undefined &&
                        updateDetails?.equifax_score !== null
                          ? updateDetails.equifax_score === -1
                            ? "New To Credit"
                            : updateDetails.equifax_score >= 650
                            ? "Good"
                            : "Poor"
                          : "N/A"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="pb-2">
                    <CreditRiskCard
                      application={{
                        equifax_details: { equifax_score: updateDetails?.equifax_score },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="overview-card">
            <p className="card-heading">Customer Details</p>
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={3}>
                  <p className="sub-heading">Pan Details</p>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Name:"}
                    text={f(updateDetails?.customer_name)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Number:"}
                    text={f(updateDetails?.pan_number)}
                  />
                <ListItemComp
                    loading={isLoading}
                    heading={"Father's Name:"}
                    text={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {fatherNameLoading ? (
                            <CircularProgress size={20} />
                          ) : isEditFatherName ? (
                          <>
                            <TextField
                              variant="outlined"
                              size="small"
                              onChange={(e) => setFatherName(e.target.value)}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                setFatherNameLoading(true);
                                setIsEditFatherName(false);
                                setFatherName(fatherName);
                                handleUpdateFatherName();
                              }}
                            >
                              Save
                            </Button>
                          </>
                        ) : (
                          <>
                            {f(updateDetails?.father_name) || fatherName}
                            {(updateDetails?.father_name === null ||
                              updateDetails?.father_name === undefined ||
                              updateDetails?.father_name === "") && (
                              <IconButton
                                size="small"
                                onClick={() => setIsEditFatherName(true)}
                                sx={{ color: 'primary.main' }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            )}
                          </>
                        )}
                      </div>
                    }
                  />

                  {Number(user?.role) !== 3 && (
                    <>
                      <div className="pe-4">
                        <hr />
                      </div>

                      <p className="sub-heading">Other Details</p>
                      <ListItemComp
                        loading={isLoading}
                        heading={"Income:"}
                        text={f(updateDetails?.income)}
                      />
                      <ListItemComp
                        loading={isLoading}
                        heading={"Education:"}
                        text={f(updateDetails?.education)}
                      />                     
                      <ListItemComp
                        loading={isLoading}
                        heading={"Cif Id:"}
                        text={isLoadingCif ? (<CircularProgress size={20}/> ): (f(updateDetails?.cif_id))}
                      />
                     {(updateDetails?.cif_id == null || updateDetails?.cif_id == "" 
                        || updateDetails?.cif_id == undefined) && (showCifButton) && 
                        (
                        <div style={{ marginLeft: "60px", marginTop: "8px" }}>
                          <Button
                            color="primary"
                            variant="contained"
                            disableElevation
                            size="small"
                            style={{
                              fontSize: 8,
                              padding: 5,
                              margin: "0px 20px",
                            }}
                            onClick={() => {
                              setShowCifButton(false);
                              setIsLoadingCif(true);
                              handleGenerateCif();
                            }}
                            disabled={!showCifButton}
                          >
                            <RefreshOutlined style={{ fontSize: "14px" }} /> Generate Cif Id
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <p className="sub-heading">Aadhaar Details</p>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Name:"}
                    text={f(updateDetails?.aadhaar_name)}
                  />
                  {[1, 5].includes(Number(user?.role)) && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"UID:"}
                      text={
                        [1, 5].includes(Number(user?.role)) &&
                        updateDetails?.aadhaar_number_unmasked
                          ? f(updateDetails?.aadhaar_number_unmasked)
                          : f(updateDetails?.aadhaar_number)
                      }
                    />
                  )}
                  <ListItemComp
                    loading={isLoading}
                    heading={"Gender:"}
                    text={f(updateDetails?.aadhaar_gender)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Address:"}
                    text={f(updateDetails?.aadhaar_address)}
                  />
                  {[1, 2, 5].includes(Number(user?.role)) && (
                    <div style={{ backgroundColor: "hsla(0, 0%, 96%, 1)" }}>
                      <ListItemComp
                        loading={isLoading || isAadhaarLoading}
                        heading={"Aadhaar Linked Mobile No.:"}
                        text={f(updateDetails?.aadhaar_mobile_number_masked)}
                      />
                      <div className="d-flex align-items-center justify-content-space-between">
                        <ListItemComp
                          loading={isLoading || isAadhaarLoading}
                          heading={"Aadhaar Linked Mobile Match :"}
                          text={f(updateDetails?.aadhaar_mobile_number_matched)}
                        />
                        <div>
                          {[1, 5].includes(Number(user?.role)) && (
                            <Button
                              color="primary"
                              variant="contained"
                              disableElevation
                              style={{
                                fontSize: 10,
                                padding: 2,
                                margin: "0px 5px",
                              }}
                              disabled={isAadhaarLoading}
                              onClick={() => {
                                handleBeFiSc();
                              }}
                            >
                              <RefreshOutlined style={{ fontSize: "14px" }} />{" "}
                              Re-Run
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} lg={5}>
                  <p className="sub-heading">Residential Address</p>
                  {Number(user?.role) === 1 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Is Property Owned:"}
                      text={f(updateDetails?.is_property_owned, "bool")}
                    />
                  )}
                  {Number(user?.role) === 1 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Owned By:"}
                      text={f(updateDetails?.owned_by)}
                    />
                  )}
                  <ListItemComp
                    loading={isLoading}
                    heading={"City:"}
                    text={f(updateDetails?.residential_address_city)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"District:"}
                    text={f(updateDetails?.residential_address_district)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"State:"}
                    text={f(updateDetails?.residential_address_state)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Pincode:"}
                    text={f(updateDetails?.residential_address_pincode)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Address:"}
                    text={f(updateDetails?.residential_address)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Ownership Proof:"}
                    text={f(updateDetails?.customer_ownership_proof)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={5}>
          <div className="overview-card">
            <p className="card-heading">Basic Loan Details</p>
            <div className="card-content">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Loan ID:"}
                    text={f(updateDetails?.loan_id)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Loan Type:"}
                    text={f(updateDetails?.loan_type)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Loan Amount:"}
                    text={f(updateDetails?.loan_amount, "cur")}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Tenure:"}
                    text={`${updateDetails?.tenure_value} ${updateDetails?.tenure_type}`}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Interest Rate:"}
                    text={f(updateDetails?.interest_rate, "rate")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Disbursement Status:"}
                    text={(() => {
                      if (updateDetails?.is_disbursed) {
                        return "Disbursed";
                      } else if (updateDetails?.is_disbursed === null) {
                        return "Not Disbursed";
                      } else {
                        return "Disbursal Rejected";
                      }
                    })()}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Disbursed Date:"}
                    text={f(updateDetails?.disbursal_date)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={7}>
          <div className="overview-card">
            <p className="card-heading">Credit Check Details</p>
            <div className="card-content">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Application ID:"}
                    text={f(updateDetails?.application_id)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Applicant Name:"}
                    text={f(updateDetails?.customer_name)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"NBFC Name:"}
                    text={f(updateDetails?.nbfc_name)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Dealer Name:"}
                    text={f(updateDetails?.anchor_name)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Applicant A/C no:"}
                    text={f(updateDetails?.account_number)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"IFSC code:"}
                    text={f(updateDetails?.ifsc_code)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Bank Name:"}
                    text={f(updateDetails?.bank_name)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Login Date:"}
                    text={f(updateDetails?.start_date, "date")}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"OEM Name:"}
                    text={f(updateDetails?.oem_name)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Vehicle Model:"}
                    text={f(updateDetails?.vehicle_model)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Chassis no:"}
                    text={f(updateDetails?.vehicle_chasis_no)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Engine no:"}
                    text={f(updateDetails?.vehicle_engine_no)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={12} sm={12}>
          <div className="overview-card">
            <p className="card-heading">Customer Addresses</p>
            <div className="card-content">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Residential Address:"}
                    text={updateDetails?.residential_address ? <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${updateDetails?.residential_address}`} >{updateDetails?.residential_address}</a> : f(null)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Aadhaar Address:"}
                    text={updateDetails?.aadhaar_address ? <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${updateDetails?.aadhaar_address}`} >{updateDetails?.aadhaar_address}</a> : f(null)}
                  />
                  {fiDoc?.length!==0 && <ListItemComp
                    loading={isLoading}
                    heading={"FI Addresses:"}
                    text={<hr/>}
                  />}
                  {fiDoc?.map(doc=>{
                    let { lat, long, starting_location } = (doc?.location || {});
                    let location = (lat ?? (starting_location?.lat)) +","+ (long ?? (starting_location?.long));
                    return (
                      <ListItemComp
                        loading={isLoading}
                        heading={`${capitalize(doc?.name,"_")}: `}
                        text={<a target="_blank" href={`https://www.google.com/maps?q=${location}`}>{location}</a>}
                      />
                    )
                  })}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <CreditEngineComp
        open={creditEngineData?.open}
        type={creditEngineData?.type}
        handleClose={() => {
          setCreditEngineData({ open: false, type: "" });
        }}
        handleReload={() => {
          getApplicationDetails(application_id);
        }}
      />
      <CopyToDemoComp
        open={copyToDemoData?.open}
        demo={demo}
        dev={dev}
        // type={copyToDemoData?.type}
        handleClose={() => {
          setCopyToDemoData({ open: false });
          window.location.reload();
        }}
      />

      {openDPD?.open && (
        <EMIDPDModal
          data={openDPD}
          handleClose={()=>{
            setOpenDPD(prev=>({...prev,open:false}))
          }}
          getStylePer={getStyle}
        />
      )}

      <Dialog
        open={openCrifData?.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>Crif Report</DialogTitle>
        <DialogContent>
          {openCrifData?.loading ? (
            <p>
              {" "}
              <CircularProgress size={12} /> Loading
            </p>
          ) : (
            <pre>{JSON.stringify(crifData, null, 2)}</pre>
          )}
          {/* <ReactJson
            src={crifData}
            collapsed={false}
            displayDataTypes={false}
            displayArrayKey={false}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <AssignNBFC
        openNBFC={openNBFC}
        setOpenNBFC={setOpenNBFC}
        setNbfcAssignData={setNbfcAssignData}
        nbfcAssignData={nbfcAssignData}
        nbfcList={nbfcList}
        Transition={Transition}
        refresh={refresh}
        application_id={application_id}
      />

      {openSactionLetter && (
        <SanctionLetterSend
          application_id={application_id}
          handleClose={() => {
            setOpenSactionLetter(false);
          }}
        />
      )}
    </>
  );
};

export default Overview;

const ListItemComp = ({ icon, heading, text, loading }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key">
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "13px" }}
        className="list-value"
        primary={
          loading ? (
            <span>
              <Skeleton />
            </span>
          ) : (
            text
          )
        }
      />
    </ListItem>
  );
};
