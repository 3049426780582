import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box, width } from "@mui/system";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./ViewApplication.css";

import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WcIcon from "@mui/icons-material/Wc";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SchoolIcon from "@mui/icons-material/School";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import Overview from "./Tabs/Overview";
import zIndex from "@mui/material/styles/zIndex";
import LoanDetails from "./Tabs/LoanDetails";
import Documents from "./Tabs/DocumentsV2";
import useApplication from "../../hooks/useApplication";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalContext";
import { f } from "../../utility/Formater";
import {
  baseURL,
  changeStageToCoCustomer,
  fetchDocuments,
  getFile,
} from "../../api";
import CoCustomerDetails from "./Tabs/CoCustomer";
import BankDetails from "./Tabs/BankDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingOver from "../../Components/Loading";
import ViewCollections from "../Tables/ViewCollection/ViewCollections";
import FIReportComponent from "../../Components/FIReport";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ViewProfile from "./Tabs/ViewProfile";
import PersonIcon from "@mui/icons-material/Person";
import shineBg from "../../assets/bgShine.png";
import { environment } from "../../config";
import ViewCollectionsV2 from "../Tables/ViewCollection/ViewCollectionsV2";
import RoomIcon from "@mui/icons-material/Room";
import { UserContext } from "../../Context/UserContext";
import GPSTab from "../Demo/GPSTab";
import { toast } from "react-toastify";
import { documentsV2List } from "../../Constants/documents";
import ContactInfoDialog from "../../Components/Dialogs/ContactInfo";

const AllDocumentsInital = {
  customer: [],
  co_customer: [],
  field_investigation: [],
  pre_disbursement: [],
  post_disbursement: [],
  other_documents: [],
  total_count: 0,
};

const ViewApplication = () => {
  const { getApplicationDetails, getNbfc } = useApplication();
  const [value, setValue] = useState(0);
  const { app_id, stage } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const sidebarDiv = useRef(null);
  const contentDiv = useRef(null);
  const sideContent = useRef(null);
  const arrowRef = useRef(null);

  const { user } = useContext(UserContext);
  const [customerPhoto, setPhoto] = useState({});
  const [AllDocuments, setAllDocuments] = useState(AllDocumentsInital);
  const [isLoading, setLoading] = useState(false);
  const [loadOverall, setLoadingOver] = useState(false);
  const [openFi, setOpenFi] = useState(false);
  const [progress, setProgress] = useState(null);
  const [sideExpand, setSideExpand] = useState(true);
  const [docStatusList, setDocStatusList] = useState({
    rejected: [],
    approval_pending: [],
    approved: [],
  });
  const [openCSTC, setOpenCSTC] = useState(false);
  const [isCSTCLoading, setIsCSTCLoading] = useState(false);

  const [openContact, setOpenContact] = useState(false);

  const { updateDetailState, customersList, panData, clearUpdateState } =
    useContext(GlobalContext);

  const [updateDetails, setUpdateDetails] = updateDetailState;

  const handleChange = (event, newValue) => {
    navigate(`/view-application/${app_id}/${newValue}`, {
      state: {
        path: state?.path,
      },
    });
    setValue(newValue);
  };

  // const getProPic = async (key) => {
  //   let {data} = await getFile(key)
  // }

  const ListItemComp = ({ icon, text, style, loading, underline }) => {
    return (
      <ListItem disablePadding className={underline ? `list-item-line` : ""}>
        <ListItemIcon className="list-icon">{icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: "15px",
            ...style,
          }}
          className="list-value"
          primary={
            loading ? (
              <span>
                <Skeleton
                  sx={{ bgcolor: "rgb(140 138 159 / 26%)", width: "75%" }}
                />
              </span>
            ) : (
              text
            )
          }
        />
      </ListItem>
    );
  };

  const getAllDocuments = async () => {
    setLoading(true);
    try {
      let { data } = await fetchDocuments(app_id);
      if (data?.status) {
        let docList = {
          customer: [],
          co_customer: [],
          field_investigation: [],
          pre_disbursement: [],
          post_disbursement: [],
          other_documents: [],
          total_count: 0,
        };

        let docs = data?.data;
        if (Number(user?.role) !== 3) {
          docs = {
            ...documentsV2List,
            ...(updateDetails?.nbfc_id === "MF00006"
              ? {
                  delivery_order_1: {
                    name: "delivery_order_1",
                    category: "pre_disbursement",
                  },
                  nach_screenshot_1: {
                    name: "nach_screenshot_1",
                    category: "pre_disbursement",
                  },
                }
              : {}),
            ...data?.data,
          };
        }
        let count = 0;
        let statusList = {
          rejected: [],
          approval_pending: [],
          approved: [],
        };
        for (let key in docs) {
          if (docs[key]?.isApproved) {
            statusList.approved.push(docs[key]);
          }

          if (
            [
              "customer_photo",
              "applicant_photo",
              "customer-photo",
              "applicant-photo",
            ].includes(
              key
                ?.replaceAll(/[0-9]/g, "")
                ?.replaceAll("_", " ")
                ?.trim()
                ?.replaceAll(" ", "_")
            )
          ) {
            setPhoto(docs[key]);
          }
          if (docs[key]?.["key"]) {
            count++;
          }
          if (docs[key]?.category === "customer") {
            docList.customer.push(docs[key]);
          } else if (
            docs[key]?.category === "co-customer" ||
            docs[key]?.category === "co_customer"
          ) {
            docList.co_customer.push(docs[key]);
          } else if (
            docs[key]?.category === "pre_disbursement" ||
            docs[key]?.category === "pre-disbursement"
          ) {
            docList.pre_disbursement.push(docs[key]);
          } else if (
            docs[key]?.category === "post_disbursement" ||
            docs[key]?.category === "post-disbursement"
          ) {
            docList.post_disbursement.push(docs[key]);
          } else if (
            docs[key]?.category === "field_investigation" ||
            docs[key]?.category === "field-investigation"
          ) {
            docList.field_investigation.push(docs[key]);
          } else {
            docList.other_documents.push(docs[key]);
          }
        }
        docList.all_documents = docs;
        docList.total_count = count;
        docList.customer = docList.customer?.sort((item1, item2) =>
          item1?.name?.localeCompare(item2?.name)
        );
        docList.co_customer = docList.co_customer?.sort((item1, item2) =>
          item1?.name?.localeCompare(item2?.name)
        );
        docList.pre_disbursement = docList.pre_disbursement?.sort(
          (item1, item2) => item1?.name?.localeCompare(item2?.name)
        );
        docList.post_disbursement = docList.post_disbursement?.sort(
          (item1, item2) => item1?.name?.localeCompare(item2?.name)
        );
        docList.field_investigation = docList.field_investigation?.sort(
          (item1, item2) => item1?.name?.localeCompare(item2?.name)
        );
        setAllDocuments(docList);
        setDocStatusList(statusList);
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getApplicationDetails(app_id, setLoadingOver);
  }, [app_id]);

  useEffect(() => {
    if (updateDetails?.application_id) {
      getAllDocuments(updateDetails?.application_id);
    }
  }, [updateDetails?.application_id]);

  useEffect(() => {
    if (stage) {
      if (stage === "assign") {
        setValue(0);
      } else {
        setValue(Number(stage));
      }
    }
  }, [stage]);

  const viewImage = (key) => {
    fetch(`${baseURL}/common/view?key=${key}`, {
      method: "get",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        application: "EMBEDDED-TOOLS",
      },
    })
      .then((res) => res.blob())
      .then((res) => {
        const href = URL.createObjectURL(res);
        setPhoto({ ...customerPhoto, photo: href });
        // aElement.setAttribute('href', href);
      });
  };

  async function handleChangeStage() {
    try {
      setIsCSTCLoading(true);
      const { data } = await changeStageToCoCustomer(
        updateDetails?.application_id
      );
      if (data?.status) {
        toast("changed stage to co-customer successfully !");
        await getApplicationDetails(updateDetails?.application_id);
        setValue(0);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsCSTCLoading(false);
  }

  useEffect(() => {
    if (customerPhoto?.key) {
      viewImage(customerPhoto?.key);
    }
  }, [customerPhoto?.key]);

  useEffect(() => {
    if (!sideExpand) {
      sidebarDiv.current.style.width = "80px";
      contentDiv.current.style.marginLeft = "80px";
      sideContent.current.style.opacity = 0;
      sideContent.current.style.overflow = "hidden";
      arrowRef.current.style.transform = "rotate(180deg)";
      setTimeout(() => {
        sideContent.current.style.height = "0px";
      }, 200);
    } else {
      sidebarDiv.current.style.width = "300px";
      contentDiv.current.style.marginLeft = "300px";
      sideContent.current.style.height = "auto";
      sideContent.current.style.opacity = 1;
      sideContent.current.style.overflow = "auto";
      arrowRef.current.style.transform = "rotate(0deg)";
    }
  }, [sideExpand]);

  const handleBack = () => {
    if (state?.path) {
      navigate("/" + state?.path);
    } else {
      navigate("/loan-applications");
    }
  };

  useEffect(() => {
    return () => {
      clearUpdateState();
    };
  }, []);

  return (
    <>
      {/* <style>{``}</style> */}
      <div
        class="sidebar"
        ref={sidebarDiv}
        style={{ backgroundImage: `url(${shineBg})` }}
      >
        <button
          class="expand-btn"
          onClick={() => {
            setSideExpand(!sideExpand);
          }}
        >
          <ArrowBackIosNewIcon className="arrow-icon" ref={arrowRef} />
        </button>
        <div>
          <div
            style={{ display: "inline-block", cursor: "pointer", fontSize: 13 }}
            onClick={() => {
              handleBack();
            }}
          >
            <ArrowBackIcon style={{ fontSize: 16 }} />{" "}
            {sideExpand && "Go back Home"}
          </div>
        </div>
        <div className="sidebar-content" ref={sideContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="content-center mt-5">
                <Avatar
                  alt={updateDetails?.customer_name}
                  src={customerPhoto?.photo}
                  sx={{ width: 100, height: 100 }}
                />
              </div>
              <p className="customer-name">
                {!loadOverall ? (
                  f(updateDetails?.customer_name, "text", true)
                ) : (
                  <Skeleton
                    sx={{ bgcolor: "rgb(140 138 159 / 26%)" }}
                    width={150}
                  />
                )}
              </p>
              <p className="customer-id">
                {!loadOverall ? (
                  f(updateDetails?.pan_number, "text", true)
                ) : (
                  <Skeleton
                    sx={{ bgcolor: "rgb(140 138 159 / 26%)" }}
                    width={100}
                  />
                )}
              </p>
            </Grid>
          </Grid>

          <Divider />

          <Grid item xs={12}>
            <p className="customer-info-list mt-2">
              <ListItemComp
                loading={loadOverall}
                icon={<CallIcon sx={{ color: "#494857" }} />}
                text={f(updateDetails?.mobile_number, "text", true)}
                style={{ color: "white", fontSize: 13, fontWeight: 400 }}
                underline={true}
              />
              <ListItemComp
                loading={loadOverall}
                icon={<MailOutlineIcon sx={{ color: "#494857" }} />}
                text={f(updateDetails?.email, "text", true)}
                style={{ color: "white", fontSize: 13, fontWeight: 400 }}
                underline={true}
              />
              <ListItemComp
                loading={loadOverall}
                icon={<CalendarMonthIcon sx={{ color: "#494857" }} />}
                text={f(updateDetails?.dob, "text", true)}
                style={{ color: "white", fontSize: 13, fontWeight: 400 }}
                underline={true}
              />
              <ListItemComp
                loading={loadOverall}
                icon={<WcIcon sx={{ color: "#494857" }} />}
                text={f(updateDetails?.gender, "text", true)}
                style={{ color: "white", fontSize: 13, fontWeight: 400 }}
                underline={true}
              />
              <ListItemComp
                loading={loadOverall}
                icon={<LocationOnIcon sx={{ color: "#494857" }} />}
                text={f(updateDetails?.residential_address, "text", true)}
                style={{ color: "white", fontSize: 12, fontWeight: 400 }}
              />

              {!loadOverall && Number(user?.role) !== 3 && (
                <Button
                  className="edit-btn"
                  onClick={() => {
                    navigate(`/update-app/${updateDetails?.application_id}`, {
                      state: {
                        path: `/view-application/${updateDetails?.application_id}`,
                      },
                    });
                  }}
                  disabled={
                    updateDetails?.send_to_nbfc ||
                    updateDetails?.is__sent_to_nbfc ||
                    Number(user?.role) === 2
                  }
                >
                  <EditIcon className="edit-icon" /> Edit Application
                </Button>
              )}

              <Button
                className="edit-btn mt-1"
                onClick={() => {
                  setOpenContact(true);
                }}
              >
                <CallIcon className="edit-icon" /> Contact info
              </Button>

              {updateDetails?.is_field_investigation_done && (
                <Button
                  className="edit-btn mt-1"
                  onClick={() => {
                    setOpenFi(true);
                  }}
                >
                  <SummarizeIcon className="edit-icon" /> FI Report
                </Button>
              )}
            </p>
          </Grid>
        </div>
      </div>

      <div class="content" ref={contentDiv}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "#cccccc",
              position: "fixed",
              width: "100%",
              bgcolor: "white",
              zIndex: 1200,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              allowScrollButtonsMobile
              scrollButtons="auto"
              TabIndicatorProps={{
                style: {
                  color: "#676767",
                  backgroundColor: "#676767",
                },
              }}
            >
              <Tab
                label={
                  <div className="tab-div">
                    <DonutLargeIcon sx={{ fontSize: 12 }} /> Overview
                  </div>
                }
                style={{ color: "#676767" }}
                {...a11yProps(0)}
                className={"profile-tab"}
                value={0}
              />
              <Tab
                label={
                  <div className="tab-div">
                    <InsertDriveFileIcon sx={{ fontSize: 12 }} /> Loan Details
                  </div>
                }
                style={{ color: "#676767" }}
                {...a11yProps(1)}
                className={"profile-tab"}
                value={1}
              />
              <Tab
                label={
                  <div className="tab-div">
                    <FolderIcon sx={{ fontSize: 12 }} /> Documents (
                    {AllDocuments?.total_count})
                  </div>
                }
                style={{ color: "#676767" }}
                {...a11yProps(2)}
                className={"profile-tab"}
                value={2}
              />

              <Tab
                label={
                  <div className="tab-div">
                    <AccountBalanceIcon sx={{ fontSize: 12 }} /> Bank Details
                  </div>
                }
                style={{ color: "#676767" }}
                {...a11yProps(3)}
                className={"profile-tab"}
                value={3}
              />

              {user?.isDemoUser && (
                <Tab
                  label={
                    <div className="tab-div">
                      <RoomIcon sx={{ fontSize: 12 }} /> GPS
                    </div>
                  }
                  style={{ color: "#676767" }}
                  {...a11yProps(4)}
                  value={4}
                  className={"profile-tab"}
                />
              )}

              <Tab
                label={
                  <div className="tab-div">
                    <CurrencyRupeeIcon sx={{ fontSize: 12 }} /> Collections
                  </div>
                }
                style={{ color: "#676767" }}
                {...a11yProps(5)}
                className={"profile-tab"}
                value={5}
              />

              {updateDetails?.is_disbursed && environment === "development" && (
                <Tab
                  label={
                    <div className="tab-div">
                      <PersonIcon sx={{ fontSize: 12 }} /> User Profile
                    </div>
                  }
                  style={{ color: "#676767" }}
                  {...a11yProps(6)}
                  className={"profile-tab"}
                  value={6}
                />
              )}

              {updateDetails?.coApplicantExist && (
                <Tab
                  label={
                    <div className="tab-div">
                      <PeopleAltIcon sx={{ fontSize: 12 }} />{" "}
                      {updateDetails?.co_app_type || "Co-customer Details"}
                    </div>
                  }
                  style={{ color: "#676767" }}
                  {...a11yProps(7)}
                  className={"profile-tab"}
                  value={7}
                />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Overview
              application_id={app_id}
              isLoading={loadOverall}
              setOpenCSTC={setOpenCSTC}
              refresh={() => {
                getApplicationDetails(app_id, setLoading);
              }}
              fiDoc={AllDocuments?.field_investigation}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LoanDetails
              application_id={app_id}
              getDetails={() => getApplicationDetails(app_id, setLoading)}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Documents
              AllDocuments={AllDocuments}
              application_id={app_id}
              setLoading={setLoading}
              setProgress={setProgress}
              getAllDocuments={getAllDocuments}
              docStatusList={docStatusList}
              getApplicationDetails={() =>
                getApplicationDetails(app_id, setLoading)
              }
              nachData={updateDetails?.digio_nach_data}
            />
          </TabPanel>

          <TabPanel value={value} index={3}>
            <BankDetails />
          </TabPanel>

          {/* <TabPanel value={value} index={4}>
            {user?.isDemoUser ? (
              <GPSTab applicationId={app_id} />
            ) : (
              <ViewCollections
                applicationId={app_id}
                payment_basis={updateDetails?.payment_basis}
                isDisbursed={updateDetails?.is_disbursed}
                nachStatus={updateDetails?.digio_nach_status}
              />
            )}
          </TabPanel> */}

          {user?.isDemoUser && (
            <TabPanel value={value} index={4}>
              <GPSTab applicationId={app_id} />
            </TabPanel>
          )}

          <TabPanel value={value} index={5}>
            <ViewCollectionsV2
              applicationId={app_id}
              payment_basis={updateDetails?.payment_basis}
              isDisbursed={updateDetails?.is_disbursed}
              nachStatus={updateDetails?.digio_nach_status}
            />
          </TabPanel>

          {updateDetails?.is_disbursed && environment === "development" && (
            <TabPanel value={value} index={6}>
              <ViewProfile
                applicationId={app_id}
                payment_basis={updateDetails?.payment_basis}
                isDisbursed={updateDetails?.is_disbursed}
              />
            </TabPanel>
          )}

          {updateDetails?.coApplicantExist && (
            <TabPanel value={value} index={7}>
              {/* <TabPanel value={value} index={5}> */}
              <CoCustomerDetails setOpenCSTC={setOpenCSTC} 
              isLoading={loadOverall}/>
            </TabPanel>
          )}
        </Box>
      </div>

      {/* {isLoading && (
        <LoadingOver
          extraBlur={true}
          text={progress !== null ? `Downloading ${progress}` : "Loading"}
        />
      )} */}
      <Dialog
        open={openCSTC}
        onClose={() => {
          setOpenCSTC(false);
        }}
      >
        <DialogTitle>
          Are you sure, you want to change the Stage to Co-customer
        </DialogTitle>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              handleChangeStage();
              setOpenCSTC(false);
            }}
          >
            Yes
          </Button>
          <Button color="error" onClick={() => setOpenCSTC(false)}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      {isCSTCLoading && <LoadingOver text={`updating stage...`} />}
      <FIReportComponent
        open={openFi}
        handleClose={() => {
          setOpenFi(false);
        }}
        fiDoc={AllDocuments?.field_investigation}
        getAllDocuments={getAllDocuments}
      />

      <ContactInfoDialog
        open={openContact}
        user={user}
        handleClose={() => {
          setOpenContact(false);
        }}
      />
    </>
  );
};

////////////////////////////////////////////////

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="pt-5"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default ViewApplication;
